import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { ScopeAblePipe } from 'app/core/pipes/scope-able.pipe';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { FilesAppService } from 'app/core/services/files.app.service';
import { BehaviorSubject, Observable, Subject, Subscription, take, takeUntil } from 'rxjs';
import {
  CreateFileRequestParams,
  DeleteFileRequestParams,
  InternalAccount,
  ModelFile,
} from '../../../../projects/tilled-api-client/src';
import { TilledButtonComponent } from '../buttons/tilled-button.component';
import { TilledSelectComponent } from '../tilled-select/tilled-select.component';
import { TilledLabelL1Component } from '../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledParagraphP4Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p4.component';
import { DragAndDropDirective } from './drag-and-drop.directive';

export interface FileViewModel {
  id?: string;
  data?: File;
  name: string;
  type: string;
  size: number;
  sizeString: string;
  state: FileViewState;
  descriptionForm: AbstractControl;
  errorMessage?: string;
}

export enum FileViewState {
  PRE_UPLOAD = 'pre_upload',
  PROCESSING = 'processing',
  SUCCESSFUL = 'successful',
  FAILED_VALIDATION = 'failed_validation',
  FAILED_SERVER = 'failed_server',
}

export enum MimeType {
  // Images
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  TIFF = 'image/tiff',
  // Text
  CSV = 'text/csv',
  // Other
  PDF = 'application/pdf',
  SVG = 'image/svg+xml',
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  standalone: true,
  imports: [
    DragAndDropDirective,
    TilledParagraphP4Component,
    MatIconModule,
    TilledParagraphP3Component,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    TilledLabelL1Component,
    TilledSelectComponent,
    MatFormFieldModule,
    MatInputModule,
    TilledButtonComponent,
    ScopeAblePipe,
    CommonModule,
  ],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput')
  private fileInput: ElementRef;
  // If fileAccountId is provided, file will be uploaded to this account
  // Used to allow partners to upload files on behalf of merchants
  @Input() fileAccountId: string;
  @Input() purpose: ModelFile.PurposeEnum;
  @Input() allowedFileTypes: ModelFile.TypeEnum[];
  @Input() fileDescriptions: string[];
  @Input() isPartnerApp: boolean = false;
  @Input() isMerchantApp: boolean = false;
  @Input() isWhiteLabelDialog: boolean = false;
  @Input() fileUploadRequired: boolean = false;
  @Input() showOverlay: boolean = false;
  @Input() existingFiles$?: Observable<ModelFile[]>;
  @Input() slim: boolean = false;
  @Input() fileCountLimit: number;
  @Input() requiredFileDescriptions: string[];
  @Output() requiredFilesUploaded: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() deleteFileCallback: () => void;
  @Input() deleteFileCallback$: Observable<any>;
  @Input() allowMultipleFiles: boolean = true;
  @Output() fileUploaded: EventEmitter<ModelFile> = new EventEmitter<ModelFile>();
  @Output() fileDeleted: EventEmitter<string> = new EventEmitter<string>();
  //can be used by the parent component to detect if user has pending files not uploaded
  //pending files are files that are not SUCCESSFUL or FAILED_VALIDATION
  @Output() pendingFilesChange: EventEmitter<number> = new EventEmitter<number>();
  private currentRequiredFilesUploaded: string[] = [];
  private pendingFiles: number = 0;

  public fileViewArray: FileViewModel[];
  private _fileViews$ = new Subject<FileViewModel[]>();
  public fileViews$ = this._fileViews$.asObservable();
  private _deletingFile$ = new BehaviorSubject<boolean>(false);
  public deletingFile$ = this._deletingFile$.asObservable();
  public uploadingFiles: boolean = false;
  public uploadableFileCount: number = 0;
  public overFileCountLimit: boolean = false;
  public disabledTooltip: string;
  public fileDescriptionOptions: { label: string; value: string }[] = [];
  public useDarkModeSecondaryColor: boolean = false;

  public descriptionsFormArray: FormArray = new FormArray([]);

  public windowWidth: any;

  private accountId: string;
  private createdFiles$: Observable<ModelFile>[];
  private deletedFile$: Observable<any>;

  private subscriptions: Subscription[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _filesAppService: FilesAppService,
    private _authService: AuthService,
    private _alertService: AlertService,
    private _brandingService: BrandingService,
  ) {
    this._brandingService.secondaryDarkColor$.subscribe((color) => {
      if (color) {
        this.useDarkModeSecondaryColor = true;
      }
    });
  }

  ngOnInit(): void {
    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account: InternalAccount) => {
      this.accountId = account.id;
    });

    this.fileDescriptionOptions = this.fileDescriptions.map((description) => ({
      label: description,
      value: description,
    }));

    this.windowWidth = window.innerWidth;
    this.fileViewArray = [];
    this.createdFiles$ = [];

    if (!this.accountId) {
      this.accountId = AuthService.getCurrentAccountId();
    }

    if (this.existingFiles$) {
      this.subscriptions.push(
        this.existingFiles$.subscribe({
          next: (existingFiles) => {
            if (existingFiles) {
              for (const file of existingFiles) {
                const fileView = this.mapModelFileToView(file);
                this.fileViewArray.push(fileView);
                this.fileViewArray.sort(this.compareFileStates);
                this._fileViews$.next(this.fileViewArray);
                this.overFileCountLimitCheck();
                const fileDescription = file.title?.split(':')[0];
                if (this.requiredFileDescriptions?.includes(fileDescription)) {
                  this.currentRequiredFilesUploaded.push(fileDescription);
                }
              }
              this.requiredFilesUploaded.emit(this.currentRequiredFilesUploaded);
            }
          },
        }),
      );
    }

    this.subscriptions.push(
      this.fileViews$.subscribe({
        next: (files) => {
          const uploadableFiles = this.fileViewArray.filter(function (file) {
            return file.state === FileViewState.FAILED_SERVER || file.state === FileViewState.PRE_UPLOAD;
          });
          this.uploadableFileCount = uploadableFiles.length;
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  public addFiles(files: FileList | File): void {
    if (files instanceof File) {
      this.processFiles(files);
    } else {
      Array.from(files).forEach((file) => {
        this.processFiles(file);
      });
    }

    this.overFileCountLimitCheck();
    this.fileViewArray.sort(this.compareFileStates);
    this._fileViews$.next(this.fileViewArray);

    //reset fileInput so the same file can be added again (this is an onChange event)
    this.fileInput.nativeElement.value = '';

    if (this.isWhiteLabelDialog) {
      this.uploadDocuments();
    } else {
      this.emitPendingFilesChange();
    }

    // auto-upload if it is a single file
    if (!this.allowMultipleFiles) {
      this.uploadDocuments();
    }
  }

  private processFiles(file: File): void {
    const fileView = this.mapBlobToView(file);

    this.fileViewArray.push(fileView);
    this._fileViews$.next(this.fileViewArray);

    const fvIndex = this.fileViewArray.indexOf(fileView);
    if (fvIndex < 0) {
      return;
    }

    if (fileView.size / 1024 / 1024 > 10.0) {
      this.fileViewArray[fvIndex].state = FileViewState.FAILED_VALIDATION;
      this.fileViewArray[fvIndex].errorMessage = 'File size too large';

      this.descriptionsFormArray.controls[fvIndex].get('description').clearValidators();
      this.descriptionsFormArray.controls[fvIndex].get('description').updateValueAndValidity();

      return;
    }

    const fileType = fileView.name.toLowerCase().split('.').pop();
    if (
      !this.validMimeForType(fileView.type, fileType as ModelFile.TypeEnum) ||
      !this.allowedFileTypes.includes(fileType as ModelFile.TypeEnum)
    ) {
      this.fileViewArray[fvIndex].state = FileViewState.FAILED_VALIDATION;
      this.fileViewArray[fvIndex].errorMessage = 'File type not allowed';

      this.descriptionsFormArray.controls[fvIndex].get('description').clearValidators();
      this.descriptionsFormArray.controls[fvIndex].get('description').updateValueAndValidity();
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.windowWidth = window.innerWidth;
  }

  public uploadDocuments(singleFile?: FileViewModel): void {
    // don't allow if form is invalid (if it is a single file it is a re-try)
    if ((this.descriptionsFormArray.invalid && !singleFile) || this.uploadableFileCount === 0 || this.uploadingFiles) {
      return;
    }

    const uploadableFiles = singleFile
      ? [singleFile]
      : this.fileViewArray.filter(function (file) {
          return file.state === FileViewState.FAILED_SERVER || file.state === FileViewState.PRE_UPLOAD;
        });

    this.uploadingFiles = true;
    let filesLeft = uploadableFiles.length;
    for (const fileView of uploadableFiles) {
      const fvIndex = this.fileViewArray.indexOf(fileView);
      if (fvIndex < 0) {
        continue;
      }

      let title: string;
      if (this.purpose === ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION) {
        let name = fileView.name.split('.');
        name.pop();
        title = fileView.descriptionForm.get('description').value + ':' + name;
      }
      const requestParams: CreateFileRequestParams = {
        tilledAccount: this.fileAccountId ?? this.accountId,
        file: fileView.data,
        purpose: this.purpose,
        title: title ?? fileView.descriptionForm.get('description').value,
      };

      const createdFile$ = this._filesAppService.createFile(requestParams);
      this.createdFiles$.push(createdFile$);

      this.fileViewArray[fvIndex].state = FileViewState.PROCESSING;
      this._fileViews$.next(this.fileViewArray);

      const obsIndex = this.createdFiles$.indexOf(createdFile$);
      if (obsIndex < 0) {
        continue;
      }
      this.subscriptions.push(
        this.createdFiles$[obsIndex].subscribe({
          next: (file) => {
            const fvIndex = this.fileViewArray.indexOf(fileView);
            if (fvIndex < 0) {
              return;
            }
            this.fileViewArray[fvIndex].state = FileViewState.SUCCESSFUL;
            this.fileViewArray[fvIndex].id = file.id;

            this._fileViews$.next(this.fileViewArray);

            this.fileUploaded.emit(file);
            const fileDescription = file.title?.split(':')[0];
            if (this.requiredFileDescriptions?.includes(fileDescription)) {
              this.currentRequiredFilesUploaded.push(fileDescription);
              this.requiredFilesUploaded.emit(this.currentRequiredFilesUploaded);
            }

            this.emitPendingFilesChange();
            filesLeft--;
            if (filesLeft === 0) {
              this.fileViewArray.sort(this.compareFileStates);
              this._fileViews$.next(this.fileViewArray);
              this.uploadingFiles = false;
            }
          },
          error: (err) => {
            const fvIndex = this.fileViewArray.indexOf(fileView);
            if (fvIndex < 0) {
              return;
            }
            this.fileViewArray[fvIndex].state = FileViewState.FAILED_SERVER;
            this.fileViewArray[fvIndex].errorMessage = err?.error?.message;

            this._fileViews$.next(this.fileViewArray);

            const message: TilledAlert = {
              message: `Failed to upload file ${this.fileViewArray[fvIndex].name}. Please try again`,
              title: 'Upload failed',
              type: 'error',
            };
            this._alertService.showAlert(message);

            this.emitPendingFilesChange();
            filesLeft--;
            if (filesLeft === 0) {
              this.fileViewArray.sort(this.compareFileStates);
              this._fileViews$.next(this.fileViewArray);
              this.uploadingFiles = false;
            }
          },
        }),
      );
    }
    this.overFileCountLimitCheck();
  }

  public beforeDeleteDocument(index: number): void {
    if (this.uploadingFiles) {
      return;
    }
    this._deletingFile$.next(true);

    if (this.deleteFileCallback$) {
      this.deleteFileCallback();
      this.subscriptions.push(
        this.deleteFileCallback$.pipe(take(1)).subscribe({
          next: (mess) => {
            if (mess === true) {
              this.deleteDocument(index);
            }
          },
        }),
      );
    } else {
      this.deleteDocument(index);
    }
  }

  public deleteDocument(index: number): void {
    if (this.uploadingFiles) {
      return;
    }

    const requestParams: DeleteFileRequestParams = {
      tilledAccount: this.fileAccountId ?? this.accountId,
      id: this.fileViewArray[index].id,
    };

    this.deletedFile$ = this._filesAppService.deleteFile(requestParams);
    this.subscriptions.push(
      this.deletedFile$.subscribe({
        next: (any) => {
          const fileDescription = this.fileViewArray[index]?.descriptionForm?.value?.description;
          this.removeFile(index);
          this.fileDeleted.emit(requestParams.id);

          if (this.requiredFileDescriptions?.includes(fileDescription)) {
            const indexToRemove = this.currentRequiredFilesUploaded.indexOf(fileDescription);
            if (indexToRemove !== -1) {
              this.currentRequiredFilesUploaded.splice(indexToRemove, 1);
              this.requiredFilesUploaded.emit(this.currentRequiredFilesUploaded);
            }
          }

          this._deletingFile$.next(false);
        },
        error: (err) => {
          const message: TilledAlert = {
            message: `Failed to delete uploaded file ${this.fileViewArray[index].name}. Please try again`,
            title: 'Deletion failed',
            type: 'error',
          };
          this._alertService.showAlert(message);
          this._deletingFile$.next(false);
        },
      }),
    );
  }

  public removeFile(index: number): void {
    if (this.uploadingFiles) {
      return;
    }

    if (index > -1) {
      this.fileViewArray.splice(index, 1);
      this.descriptionsFormArray.removeAt(index);
      this._fileViews$.next(this.fileViewArray);
      this.emitPendingFilesChange();
    }
    this.overFileCountLimitCheck();
  }

  private compareFileStates(fileA: FileViewModel, fileB: FileViewModel) {
    if (fileA.state === FileViewState.SUCCESSFUL) {
      if (fileB.state === FileViewState.SUCCESSFUL) {
        return 0;
      } else if (fileB.state === FileViewState.FAILED_SERVER || fileB.state === FileViewState.FAILED_VALIDATION) {
        return -1;
      } else {
        return -1;
      }
    } else if (fileA.state === FileViewState.FAILED_SERVER || fileA.state === FileViewState.FAILED_VALIDATION) {
      if (fileB.state === FileViewState.SUCCESSFUL) {
        return 1;
      } else if (fileB.state === FileViewState.FAILED_SERVER || fileB.state === FileViewState.FAILED_VALIDATION) {
        return 0;
      } else {
        return -1;
      }
    } else {
      if (fileB.state === FileViewState.SUCCESSFUL) {
        return 1;
      } else if (fileB.state === FileViewState.FAILED_SERVER || fileB.state === FileViewState.FAILED_VALIDATION) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  private mapBlobToView(file: File): FileViewModel {
    const i = file.size === 0 ? 0 : Math.floor(Math.log(file.size) / Math.log(1024));
    const sizeString = '(' + (file.size / Math.pow(1024, i)).toFixed(1) + ['B', 'KB', 'MB', 'GB', 'TB'][i] + ')';

    let defaultDescription: string = null;
    if (this.fileDescriptions?.length === 1) {
      defaultDescription = this.fileDescriptions[0];
    }

    this.descriptionsFormArray.push(
      new FormGroup({
        description: new FormControl<string | null>(defaultDescription ?? null, [Validators.required]),
      }),
    );

    const fileView: FileViewModel = {
      data: file,
      name: file.name,
      size: file.size,
      sizeString: sizeString,
      type: file.type,
      state: FileViewState.PRE_UPLOAD,
      descriptionForm: this.descriptionsFormArray.controls[this.descriptionsFormArray.length - 1],
    };

    return fileView;
  }

  private mapModelFileToView(file: ModelFile): FileViewModel {
    const i = file.size === 0 ? 0 : Math.floor(Math.log(file.size) / Math.log(1024));
    const sizeString = '(' + (file.size / Math.pow(1024, i)).toFixed(1) + ['B', 'KB', 'MB', 'GB', 'TB'][i] + ')';

    let title: string;
    let name: string;
    if (file.purpose === ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION) {
      let temp = file.title ? file.title.split(':') : ['[no description]', '[no name]'];
      name = temp.length > 1 ? temp.pop() : temp[0];
      title = temp[0];
    }
    this.descriptionsFormArray.push(
      new FormGroup({
        description: new FormControl<string | null>(title ?? file.title, [Validators.required]),
      }),
    );

    const fileView: FileViewModel = {
      id: file.id,
      name: name ? name + '.' + file.type : file.filename,
      size: file.size,
      sizeString: sizeString,
      type: file.type,
      state: FileViewState.SUCCESSFUL,
      descriptionForm: this.descriptionsFormArray.controls[this.descriptionsFormArray.length - 1],
    };

    return fileView;
  }

  private validMimeForType(mimeType: string, type: ModelFile.TypeEnum) {
    switch (type) {
      case ModelFile.TypeEnum.JPG:
        return mimeType === MimeType.JPEG || mimeType === MimeType.JPG;
      case ModelFile.TypeEnum.PNG:
        return mimeType === MimeType.PNG;
      case ModelFile.TypeEnum.TIFF:
        return mimeType === MimeType.TIFF;
      case ModelFile.TypeEnum.CSV:
        return mimeType === MimeType.CSV;
      case ModelFile.TypeEnum.PDF:
        return mimeType === MimeType.PDF;
      default:
        return false;
    }
  }

  private emitPendingFilesChange(): void {
    const pendingFiles = this.fileViewArray.filter(function (file) {
      return !(file.state === FileViewState.SUCCESSFUL || file.state === FileViewState.FAILED_VALIDATION);
    });

    if (this.pendingFiles !== pendingFiles.length) {
      this.pendingFiles = pendingFiles.length;
      this.pendingFilesChange.emit(this.pendingFiles);
    }
  }

  public changeOverlay(value: boolean): void {
    this.showOverlay = value;
  }

  private overFileCountLimitCheck(): void {
    if (this.fileCountLimit) {
      this.overFileCountLimit = this.fileViewArray.length >= this.fileCountLimit;
      const preUpload = this.fileViewArray.filter((file) => file.state === FileViewState.PRE_UPLOAD);
      if (this.overFileCountLimit && preUpload.length === 0) {
        this.disabledTooltip = 'Delete existing file(s) to upload a new one.';
      } else {
        this.disabledTooltip = '';
      }
    }
  }
}
