import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { TilledLabelL1Component, TilledParagraphP2Component } from 'app/shared/tilled-text';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, Subscription, takeUntil } from 'rxjs';
import {
  CardCheckoutMethodBreakdown,
  InternalPartnerOnboardingDefaultSettings,
  OnboardingApplication,
  PricingTemplate,
  ProcessingVolume,
} from '../../../../../projects/tilled-api-client/src';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledHeadingH4Component } from '../../tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'processing-volumes-merchant-step',
  templateUrl: './processing-volumes-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    TilledHeadingH4Component,
    TilledParagraphP3Component,
    TilledParagraphP2Component,
    TilledInputComponent,
    TilledSelectComponent,
    TilledLabelL1Component,
    CommonModule,
  ],
})
export class ProcessingVolumesMerchantStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Input() partnerDefaultSettings$: Observable<InternalPartnerOnboardingDefaultSettings>;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  private subscriptions: Subscription[] = [];
  public processingVolumesForm: FormGroup;
  public merchantApp: OnboardingApplication;
  public cardMin: number = 1;
  public cardMax: number = 100000000;
  public debitMin: number = 1;
  public debitMax: number = 100000000;
  public monthMin: number = 1;
  public monthMax: number = 100000000;

  public volumeRangeTypes = SelectorTypes.YearlyVolumeRangeTypes;

  public hasCard: boolean = false;
  public hasCardPresent: boolean = false;
  public hasDebit: boolean = false;

  public previousProcessorOptions: string[] = [
    'I’ve never processed payments before',
    'Paypal',
    'Stripe',
    'Square',
    'Authorize.net',
    'Adyen',
    'TSYS',
    'Other',
  ];
  public previousProcessorMap: { label: string; value: string }[] = this.previousProcessorOptions.map((processor) => ({
    label: processor,
    value: processor,
  }));

  public shippingTimesTypes = SelectorTypes.daysPriorToShipmentArray;
  public shippingTimesTypesMap: { label: string; value: number }[] = this.shippingTimesTypes.map((type) => ({
    label: type.desc,
    value: type.name,
  }));

  private _showPreviousProcessorOther$ = new BehaviorSubject<boolean>(false);
  public showPreviousProcessorOther$ = this._showPreviousProcessorOther$.asObservable();

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
  ) {
    super();
    this.partnerDefaultSettings$ = this._merchantAppService.partnerOnboardingDefaults$;
  }

  ngOnInit(): void {
    this.processingVolumesForm = this._formBuilder.group({
      previousProcessor: new FormControl<string | null>(
        this.merchantApp?.legal_entity?.existing_processor_name || null,
      ),
      previousProcessorOther: new FormControl<string | null>(
        this.merchantApp?.legal_entity?.existing_processor_name || null,
      ),
      daysPriorToShipping: new FormControl<number | null>(
        this.merchantApp?.legal_entity?.days_billed_prior_to_shipment || null,
      ),
      percentsFormGroup: new FormGroup(
        {
          cardPresent: new FormControl<number | null>(
            this.merchantApp?.legal_entity?.card_checkout_method_breakdown?.percent_swiped || null,
            [Validators.min(0)],
          ),
          eCommerce: new FormControl<number | null>(
            this.merchantApp?.legal_entity?.card_checkout_method_breakdown?.percent_e_commerce || null,
            [Validators.min(0)],
          ),
          cardNotPresent: new FormControl<number | null>(
            this.merchantApp?.legal_entity?.card_checkout_method_breakdown?.percent_manual_card_not_present || null,
            [Validators.min(0)],
          ),
        },
        this.percentagesSumTo100Validator(),
      ),
      numberOfTerminals: new FormControl<number | null>(this.merchantApp?.legal_entity?.number_of_terminals || null, [
        Validators.min(1),
        Validators.max(10),
      ]),
      averageMonthlyTransactions: new FormControl<number | null>(
        this.merchantApp?.legal_entity?.processing_volume?.monthly_transaction_count || null,
        [Validators.min(1)],
      ),
      averageMonthlyVolume: new FormControl<number | null>(
        this.merchantApp?.legal_entity?.processing_volume?.monthly_processing_volume
          ? this.merchantApp.legal_entity.processing_volume.monthly_processing_volume / 100
          : null,
        [Validators.min(1), Validators.max(100000000)],
      ),
      averageTransactionAmountCard: new FormControl<number | null>(
        this.merchantApp?.legal_entity?.processing_volume?.average_transaction_amount_card
          ? this.merchantApp.legal_entity.processing_volume.average_transaction_amount_card / 100
          : null,
        [Validators.min(1), Validators.max(100000000)],
      ),
      averageTransactionAmountDebit: new FormControl<number | null>(
        this.merchantApp?.legal_entity?.processing_volume?.average_transaction_amount_debit
          ? this.merchantApp.legal_entity.processing_volume.average_transaction_amount_debit / 100
          : null,
        [Validators.min(1), Validators.max(100000000)],
      ),
    });

    this.partnerDefaultSettings$.subscribe((result) => {
      if (result) {
        if (result.card_transaction_min || result.card_transaction_max) {
          this.cardMin = result.card_transaction_min / 100 || 1;
          this.cardMax = result.card_transaction_max / 100 || 10000000;
          this.processingVolumesForm
            .get('averageTransactionAmountCard')
            .setValidators([Validators.min(this.cardMin), Validators.max(this.cardMax)]);
        }

        if (result.debit_transaction_min || result.debit_transaction_max) {
          this.debitMin = result.debit_transaction_min / 100 || 1;
          this.debitMax = result.debit_transaction_max / 100 || 10000000;
          this.processingVolumesForm
            .get('averageTransactionAmountDebit')
            .setValidators([Validators.min(this.debitMin), Validators.max(this.debitMax)]);
          this.processingVolumesForm.get('averageTransactionAmountDebit').updateValueAndValidity();
        }

        if (result.monthly_volume_min || result.monthly_volume_max) {
          this.monthMin = result.monthly_volume_min / 100 || 1;
          this.monthMax = result.monthly_volume_max / 100 || 10000000;
          this.processingVolumesForm
            .get('averageMonthlyVolume')
            .setValidators([Validators.min(this.monthMin), Validators.max(this.monthMax)]);
        }
      }
    });
    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.processingVolumesForm.disable();
          } else {
            this.processingVolumesForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onBackClicked(event: string): void {
    this._merchantAppService.updateCurrentStep(this.stepNumber - 1);
  }

  onContinueClicked(accountId?: string) {
    this.processingVolumesForm.markAllAsTouched();
    if (!this.hasCardPresent) {
      this.processingVolumesForm.removeControl('numberOfTerminals');
    }
    if (this.processingVolumesForm.invalid) {
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    for (const field in this.processingVolumesForm.controls) {
      const control = this.processingVolumesForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    if (!this.merchantApp.legal_entity.processing_volume) {
      this.merchantApp.legal_entity.processing_volume = {} as ProcessingVolume;
    }

    if (this.hasCard) {
      this.merchantApp.legal_entity.processing_volume.average_transaction_amount_card = this.processingVolumesForm.value
        .averageTransactionAmountCard
        ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageTransactionAmountCard)
        : null;
    }
    if (this.hasDebit) {
      this.merchantApp.legal_entity.processing_volume.average_transaction_amount_debit = this.processingVolumesForm
        .value.averageTransactionAmountDebit
        ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageTransactionAmountDebit)
        : null;
    }
    if (!this.hasCard && !this.hasDebit && !environment.production) {
      //no pricing templates, set default value
      this.merchantApp.legal_entity.processing_volume.average_transaction_amount_card = 1;
    }

    this.merchantApp.legal_entity.processing_volume.monthly_transaction_count =
      this.processingVolumesForm.value.averageMonthlyTransactions;
    this.merchantApp.legal_entity.processing_volume.monthly_processing_volume = this.processingVolumesForm.value
      .averageMonthlyVolume
      ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageMonthlyVolume)
      : null;

    if (this.processingVolumesForm.value.previousProcessor === 'Other') {
      this.merchantApp.legal_entity.existing_processor_name = this.processingVolumesForm.value.previousProcessorOther;
    } else {
      this.merchantApp.legal_entity.existing_processor_name = this.processingVolumesForm.value.previousProcessor;
    }

    this.merchantApp.legal_entity.days_billed_prior_to_shipment = this.processingVolumesForm.value.daysPriorToShipping;

    if (!this.merchantApp.legal_entity.card_checkout_method_breakdown) {
      this.merchantApp.legal_entity.card_checkout_method_breakdown = {} as CardCheckoutMethodBreakdown;
    }
    this.merchantApp.legal_entity.card_checkout_method_breakdown.percent_swiped = this.processingVolumesForm.get(
      'percentsFormGroup.cardPresent',
    ).value
      ? parseInt(this.processingVolumesForm.get('percentsFormGroup.cardPresent').value, 10)
      : 0;
    this.merchantApp.legal_entity.card_checkout_method_breakdown.percent_manual_card_not_present =
      this.processingVolumesForm.get('percentsFormGroup.cardNotPresent').value
        ? parseInt(this.processingVolumesForm.get('percentsFormGroup.cardNotPresent').value, 10)
        : 0;
    this.merchantApp.legal_entity.card_checkout_method_breakdown.percent_e_commerce = this.processingVolumesForm.get(
      'percentsFormGroup.eCommerce',
    ).value
      ? parseInt(this.processingVolumesForm.get('percentsFormGroup.eCommerce').value, 10)
      : 0;

    this.merchantApp.legal_entity.number_of_terminals = this.processingVolumesForm.get(
      'percentsFormGroup.numberOfTerminals',
    )?.value
      ? parseInt(this.processingVolumesForm.get('percentsFormGroup.numberOfTerminals').value, 10)
      : 1;
    this._merchantAppService.updateMerchantApplication(this.merchantApp, this.stepNumber + 1, accountId);
  }

  private isAppUnsaved(): boolean {
    for (const field in this.processingVolumesForm.controls) {
      const control = this.processingVolumesForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    return !(this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_card
      ? this.merchantApp.legal_entity.processing_volume.average_transaction_amount_card / 100 ==
        this.processingVolumesForm.value.averageTransactionAmountCard
      : null == this.processingVolumesForm.value.averageTransactionAmountCard &&
          this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_debit
        ? this.merchantApp.legal_entity.processing_volume.average_transaction_amount_debit / 100 ==
          this.processingVolumesForm.value.averageTransactionAmountDebit
        : null == this.processingVolumesForm.value.averageTransactionAmountDebit &&
            this.merchantApp.legal_entity?.processing_volume?.monthly_processing_volume
          ? this.merchantApp.legal_entity.processing_volume.monthly_processing_volume / 100 ==
            this.processingVolumesForm.value.averageMonthlyVolume
          : null == this.processingVolumesForm.value.averageMonthlyVolume &&
            this.merchantApp.legal_entity?.processing_volume?.monthly_transaction_count ==
              this.processingVolumesForm.value.averageMonthlyTransactions);
  }

  private resetApplication(): void {
    if (
      this.previousProcessorOptions.includes(this.merchantApp.legal_entity?.existing_processor_name) &&
      this.merchantApp.legal_entity?.existing_processor_name !== 'Other'
    ) {
      this.processingVolumesForm.controls['previousProcessor'].setValue(
        this.merchantApp.legal_entity?.existing_processor_name,
      );
    } else {
      if (this.merchantApp.legal_entity?.existing_processor_name) {
        this.processingVolumesForm.controls['previousProcessor'].setValue('Other');
      }
      this.processingVolumesForm.controls['previousProcessorOther'].setValue(
        this.merchantApp.legal_entity.existing_processor_name,
      );
    }
    this.onPreviousProcessorChange();

    this.processingVolumesForm.controls['daysPriorToShipping'].setValue(
      this.merchantApp.legal_entity?.days_billed_prior_to_shipment,
    );

    this.processingVolumesForm.controls['averageMonthlyTransactions'].setValue(
      this.merchantApp.legal_entity?.processing_volume?.monthly_transaction_count,
    );

    this.processingVolumesForm.controls['averageMonthlyVolume'].setValue(
      this.merchantApp.legal_entity?.processing_volume?.monthly_processing_volume
        ? this.merchantApp.legal_entity?.processing_volume?.monthly_processing_volume / 100
        : null,
    );

    const cardPricing = this.merchantApp?.pricing_templates
      ? this.merchantApp.pricing_templates.find(
          (p) => p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD,
        )
      : null;
    const cardPresentPricing = this.merchantApp?.pricing_templates
      ? this.merchantApp.pricing_templates.find(
          (p) => p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD_PRESENT,
        )
      : null;
    this.hasCard = cardPricing || cardPresentPricing ? true : false;
    this.hasCardPresent = cardPresentPricing ? true : false;
    if (this.hasCard) {
      this.processingVolumesForm.controls['averageTransactionAmountCard'].setValue(
        this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_card
          ? this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_card / 100
          : null,
      );
    }

    const debitPricing = this.merchantApp?.pricing_templates
      ? this.merchantApp.pricing_templates.find(
          (p) =>
            p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
            p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
        )
      : null;
    this.hasDebit = debitPricing ? true : false;
    if (this.hasDebit) {
      this.processingVolumesForm.controls['averageTransactionAmountDebit'].setValue(
        this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_debit
          ? this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_debit / 100
          : null,
      );
    }
    this.processingVolumesForm
      .get('percentsFormGroup.cardPresent')
      .setValue(this.merchantApp.legal_entity?.card_checkout_method_breakdown?.percent_swiped);
    this.processingVolumesForm
      .get('percentsFormGroup.eCommerce')
      .setValue(this.merchantApp.legal_entity?.card_checkout_method_breakdown?.percent_e_commerce);
    this.processingVolumesForm
      .get('percentsFormGroup.cardNotPresent')
      .setValue(this.merchantApp.legal_entity?.card_checkout_method_breakdown?.percent_manual_card_not_present);

    this.processingVolumesForm.controls['numberOfTerminals']?.setValue(
      this.merchantApp.legal_entity?.number_of_terminals,
    );
  }

  onPreviousProcessorChange(): void {
    const previousProcessor = this.processingVolumesForm.value.previousProcessor;
    if (previousProcessor === 'Other') {
      this._showPreviousProcessorOther$.next(true);
    } else {
      this._showPreviousProcessorOther$.next(false);
    }
  }

  percentagesSumTo100Validator(minRequired = 100, maxRequired = 100): ValidatorFn {
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    return function validate(formGroup: FormGroup) {
      let total = 0;

      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        total += control.value ? parseInt(control.value, 10) : 0;
      });

      if (total < minRequired || total > maxRequired) {
        return {
          percentagesSumTo100: true,
        };
      }

      return null;
    };
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }
}
