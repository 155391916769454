import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, CommonModule, KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { AuthService } from 'app/core/services/auth.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { AccountDocumentsUploadComponent } from 'app/shared/account-documents/upload/account-documents-upload.component';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import {
  TilledHeadingH3Component,
  TilledHeadingH4Component,
  TilledParagraphP1Component,
  TilledParagraphP2Component,
  TilledParagraphP3Component,
} from 'app/shared/tilled-text';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { isValidYear } from 'app/shared/validators/dob.validator';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, Subscription, takeUntil } from 'rxjs';
import {
  InternalPartnerOnboardingDefaultSettings,
  OnboardingApplication,
} from '../../../../../projects/tilled-api-client/src';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';

@Component({
  selector: 'business-details-merchant-step',
  templateUrl: './business-details-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    TilledInputComponent,
    TilledLabelL1Component,
    TilledSelectComponent,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    AsyncPipe,
    TilledHeadingH4Component,
    TilledHeadingH3Component,
    TilledParagraphP3Component,
    TilledParagraphP2Component,
    TilledParagraphP1Component,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    CommonModule,
    AccountDocumentsUploadComponent,
  ],
})
export class BusinessDetailsStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Input() partnerDefaultSettings$: Observable<InternalPartnerOnboardingDefaultSettings>;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  public businessDetailsForm: FormGroup;
  public merchantApp: OnboardingApplication;
  public entityTypes = SelectorTypes.businessEntityTypes;
  public entityTypesOptions: { label: string; value: string }[] = this.entityTypes.map((type) => ({
    label: type.desc,
    value: type.name,
  }));
  private subscriptions: Subscription[] = [];

  public stateCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.statesMap).map(
    ([value, label]) => ({ label, value }),
  );
  public mccCodeMap: { label: string; value: string }[] = [];
  private _mccsLoaded$ = new BehaviorSubject<boolean>(false);
  public mccsLoaded$ = this._mccsLoaded$.asObservable();
  private onboardingDefaults: InternalPartnerOnboardingDefaultSettings;

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
  ) {
    super();
    this.partnerDefaultSettings$ = this._merchantAppService.partnerOnboardingDefaults$;
  }

  ngOnInit(): void {
    this._merchantAppService.getMccDescriptions(AuthService.getCurrentAccountId()).subscribe({
      next: (response) => {
        if (!response) {
          return;
        }
        this.mccCodeMap = response
          .map((item) => {
            return {
              label: item.description,
              value: item.code,
            };
          })
          .sort((a, b) => {
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            return 0;
          });

        if (this.onboardingDefaults?.category_codes) {
          const filteredMccs = this.mccCodeMap.filter((code) => {
            return this.onboardingDefaults.category_codes.includes(code.value as any);
          });
          this.mccCodeMap = filteredMccs;
        }
        this._mccsLoaded$.next(true);
      },
    });

    this.businessDetailsForm = this._formBuilder.group({
      legalName: new FormControl<string | null>(this.merchantApp?.legal_entity?.legal_name || null),
      dba: new FormControl<string | null>(
        this.merchantApp?.legal_entity?.dba_name || this.merchantApp?.legal_entity?.legal_name || null,
      ),
      type: new FormControl<string | null>(this.merchantApp?.legal_entity?.structure || null),
      businessIdentifier: new FormControl<string | null>(this.merchantApp?.legal_entity?.tax_id_number || null, [
        Validators.minLength(9),
      ]),
      dateOfInc: new FormControl<string | null>(
        this.merchantApp?.legal_entity.date_of_incorporation
          ? moment(this.merchantApp?.legal_entity.date_of_incorporation).format('MM/DD/YYYY')
          : null,
        [isValidYear(1600)],
      ),
      category: new FormControl<string | null>(this.merchantApp?.legal_entity?.mcc || null),
      statementDescriptor: new FormControl<string | null>(this.merchantApp?.legal_entity?.statement_descriptor || null),
      description: new FormControl<string | null>(this.merchantApp?.legal_entity?.product_description || null),
      b2bPercent: new FormControl<number | null>(this.merchantApp?.legal_entity?.percent_business_to_business || null, [
        Validators.min(0),
        Validators.max(100),
      ]),
    });

    this.partnerDefaultSettings$.subscribe((result) => {
      if (result) {
        this.onboardingDefaults = result;
      }
    });
    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.businessDetailsForm.disable();
          } else {
            this.businessDetailsForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onContinueClicked(accountId?: string): void {
    this.businessDetailsForm.markAllAsTouched();
    if (this.businessDetailsForm.invalid) {
      this.scrollToError();
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    for (const field in this.businessDetailsForm.controls) {
      const control = this.businessDetailsForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    this.merchantApp.legal_entity.legal_name = this.businessDetailsForm.value.legalName;
    this.merchantApp.legal_entity.dba_name = this.businessDetailsForm.value.dba;
    this.merchantApp.legal_entity.structure = this.businessDetailsForm.value.type;
    this.merchantApp.legal_entity.tax_id_number = this.businessDetailsForm.value.businessIdentifier;
    this.merchantApp.legal_entity.date_of_incorporation = moment(
      this.businessDetailsForm.value.dateOfInc,
      'MM-DD-YYYY',
    ).toISOString();
    this.merchantApp.legal_entity.mcc = this.businessDetailsForm.value.category;
    this.merchantApp.legal_entity.percent_business_to_business = parseInt(
      this.businessDetailsForm.value.b2bPercent,
      10,
    );
    this.merchantApp.legal_entity.statement_descriptor = this.businessDetailsForm.value.statementDescriptor;
    this.merchantApp.legal_entity.product_description = this.businessDetailsForm.value.description;
    this._merchantAppService.updateMerchantApplication(this.merchantApp, this.stepNumber + 1, accountId);
  }

  private isAppUnsaved(): boolean {
    return !(
      _compareTwoStrings(this.merchantApp.legal_entity?.legal_name, this.businessDetailsForm.value.legalName) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.dba_name, this.businessDetailsForm.value.dba) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.structure, this.businessDetailsForm.value.type) &&
      _compareTwoStrings(
        this.merchantApp.legal_entity?.tax_id_number,
        this.businessDetailsForm.value.businessIdentifier,
      ) &&
      _compareTwoStrings(
        this.businessDetailsForm.value.dateOfInc,
        this.merchantApp.legal_entity?.date_of_incorporation
          ? moment(this.merchantApp.legal_entity?.date_of_incorporation).format('MM/DD/YYYY').toString()
          : null,
      ) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.mcc, this.businessDetailsForm.value.category) &&
      _compareTwoStrings(
        this.merchantApp.legal_entity?.statement_descriptor,
        this.businessDetailsForm.value.statementDescriptor,
      ) &&
      this.merchantApp.legal_entity?.percent_business_to_business === this.businessDetailsForm.value.b2bPercent &&
      _compareTwoStrings(this.merchantApp.legal_entity?.product_description, this.businessDetailsForm.value.description)
    );
  }

  private resetApplication(): void {
    const ble = this.merchantApp?.legal_entity;

    this.businessDetailsForm.controls['legalName'].setValue(ble?.legal_name);
    this.businessDetailsForm.controls['dba'].setValue(ble?.dba_name || ble?.legal_name);
    this.businessDetailsForm.controls['type'].setValue(ble?.structure);
    this.businessDetailsForm.controls['businessIdentifier'].setValue(ble?.tax_id_number);
    this.businessDetailsForm.controls['dateOfInc'].setValue(
      ble?.date_of_incorporation ? moment(ble.date_of_incorporation).format('MM/DD/YYYY') : null,
    );
    this.businessDetailsForm.controls['category'].setValue(ble?.mcc);
    this.businessDetailsForm.controls['statementDescriptor'].setValue(ble?.statement_descriptor);
    this.businessDetailsForm.controls['description'].setValue(ble?.product_description);
    this.businessDetailsForm.controls['b2bPercent'].setValue(ble?.percent_business_to_business);

    // Update defaults after any app reset.
    if (this.onboardingDefaults) {
      if (this.onboardingDefaults.business_types) {
        const filteredOptions = this.entityTypesOptions.filter((entity) =>
          this.onboardingDefaults.business_types.includes(entity.value as any),
        );
        this.entityTypesOptions = filteredOptions;
      }

      if (this.onboardingDefaults.category_codes) {
        const filteredMccs = this.mccCodeMap.filter((code) => {
          return this.onboardingDefaults.category_codes.includes(code.value as any);
        });
        this.mccCodeMap = filteredMccs;
      }

      if (!ble?.product_description && this.onboardingDefaults.description) {
        this.businessDetailsForm.controls['description'].setValue(this.onboardingDefaults.description);
      }
    }
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.mat-form-field-invalid');
    this.scrollTo(firstElementWithError);
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  public readable(input: string): string {
    let pieces = input.toLowerCase().split('_');
    for (let i = 0; i < pieces.length; i++) {
      if (!(pieces[i] === 'and' || pieces[i] === 'of' || pieces[i] === 'to' || pieces[i] === 'to')) {
        pieces[i] = pieces[i].charAt(0).toUpperCase() + pieces[i].slice(1);
      }
    }
    return pieces.join(' ');
  }
}
