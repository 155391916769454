export const environment = {
  env: 'sandbox',
  api: 'https://sandbox-api.tilled.com',
  appUrl: 'sandbox-app.tilled.com',
  whiteLabelApi: 'https://api.sandbox-paymentsonline.io',
  whiteLabelAppHost: 'sandbox-paymentsonline.io',
  sentryDSN: 'https://41ee08ccbfb64c0386f90376b569ab93@o377738.ingest.sentry.io/6582075',
  production: false,
  hmr: false,
  whiteLabelDomain: '.sandbox-paymentsonline.io',
  tracesSampleRate: 1.0,
  replaysSamplingRate: 0,
  replaysOnErrorSamplingRate: 0,
};
