import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { HOMEPAGE_ROUTE } from 'app/core/constants';
import { Observable, map } from 'rxjs';
import { InternalAccountCapability } from '../../../../../projects/tilled-api-client/src';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingRouteGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.user && !this.authService.isMerchantUser()) {
      this.router.navigate([HOMEPAGE_ROUTE]);
      return false;
    }

    return this.authService.account$.pipe(
      map((account) => {
        if (
          account.capabilities.find((cap) =>
            [InternalAccountCapability.StatusEnum.ACTIVE, InternalAccountCapability.StatusEnum.DISABLED].includes(
              cap.status,
            ),
          )
        ) {
          if (
            account.capabilities.find(
              (cap) =>
                (cap.provider_type === InternalAccountCapability.ProviderTypeEnum.TSYS ||
                  cap.provider_type === InternalAccountCapability.ProviderTypeEnum.VALOR) &&
                cap.status === InternalAccountCapability.StatusEnum.IN_REVIEW,
            ) &&
            state.url.includes('onboarding/sign')
          ) {
            return true;
          }
          this.router.navigate([HOMEPAGE_ROUTE]);
          return false;
        }
        return true;
      }),
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // If it is a partner, then redirect to dashbaord. Only merchant users should be using this route
    if (this.authService.user && !this.authService.isMerchantUser()) {
      this.router.navigate([HOMEPAGE_ROUTE]);
      return false;
    }

    return this.authService.account$.pipe(
      map((account) => {
        if (account.capabilities.find((cap) => cap.status === InternalAccountCapability.StatusEnum.ACTIVE)) {
          if (
            account.capabilities.find(
              (cap) =>
                (cap.provider_type === InternalAccountCapability.ProviderTypeEnum.TSYS ||
                  cap.provider_type === InternalAccountCapability.ProviderTypeEnum.VALOR) &&
                cap.status === InternalAccountCapability.StatusEnum.IN_REVIEW,
            ) &&
            state.url.includes('onboarding/sign')
          ) {
            return true;
          }
          this.router.navigate([HOMEPAGE_ROUTE]);
          return false;
        }
        return true;
      }),
    );
  }
}
