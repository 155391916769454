import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FormCardComponent } from 'app/shared/cards/form-cards/form-card.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  CreateUserInvitationRequestParams,
  UserInvitationCreateParams,
} from '../../../../projects/tilled-api-client/src';
import { FuseAlertService } from '../../../@fuse/components/alert';
import { FuseAlertComponent } from '../../../@fuse/components/alert/alert.component';
import { ComponentBase } from '../../core/componentBase';
import { TilledAlert } from '../../core/models/tilled-alert';
import { AlertService } from '../../core/services/alert.service';
import { AuthService } from '../../core/services/auth.service';
import { UsersAppService } from '../../core/services/users.app.service';
import { TilledButtonComponent } from '../buttons/tilled-button.component';
import { TilledInputComponent } from '../form-fields/tilled-input/tilled-input.component';
import { TilledHeadingH2Component } from '../tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledParagraphP3Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'merchant-app-invitation-dialog',
  templateUrl: './merchant-app-invitation-dialog.component.html',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    TilledHeadingH2Component,
    TilledParagraphP3Component,
    TilledInputComponent,
    TilledButtonComponent,
    FormCardComponent,
    FuseAlertComponent,
    CommonModule,
    MatTooltipModule,
  ],
})
export class MerchantAppInvitationDialogComponent extends ComponentBase implements OnInit, OnDestroy {
  public userInviteForm: FormGroup;
  public accountId: string = '';
  public title: string;
  public legalName: string;
  public showCopyUrlButton: boolean;
  public submitButtonText: string;
  public showLinkToUsers: boolean;
  public inviteText: string;
  public alertMessage: string;
  public accountUserCount: number = 0;
  public disabled: boolean = false;
  public applicantInvite: boolean = false;
  private usersCount$: Observable<number>;
  private userInvitationsCount$: Observable<number>;
  private invitationResponse$: Observable<any>;
  private _displayAlert$ = new Subject<boolean>();
  public displayAlert$ = this._displayAlert$.asObservable();
  public emailExists: boolean = false;
  private action: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: InviteMerchantDialogData,
    private dialogRef: MatDialogRef<MerchantAppInvitationDialogComponent>,
    private _formBuilder: FormBuilder,
    private _fuseAlertService: FuseAlertService,
    private _usersAppService: UsersAppService,
    private _router: Router,
    private _clipboard: Clipboard,
    private _alertService: AlertService,
    private _authService: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.accountId = this._data.accountId;
    this.title = this._data.title;
    this.legalName = this._data.legalName;
    this.inviteText = this._data.inviteText;
    this.submitButtonText = this._data.submitButtonText;
    this.showCopyUrlButton = this._data.showCopyUrlButton;
    this.showLinkToUsers = this._data.showLinkToUsers;
    this.emailExists = this._data.emailExists;
    this.applicantInvite = this._data.applicantInvite;

    this.usersCount$ = this._usersAppService.usersCount$;
    this.userInvitationsCount$ = this._usersAppService.userInvitationsCount$;
    this.invitationResponse$ = this._usersAppService.userInvitationResponse$;
    this.userInvitationsCount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((res1) => {
      this.usersCount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((res2) => {
        this.accountUserCount = res1 + res2;
        if (this.accountUserCount === 0) {
          this.inviteText =
            'To share the application with a business owner, generate a unique link or send an automated email.';
        } else {
          this.inviteText =
            'To share the application with a business representative, generate a unique link or send an automated email.';
        }
        if (this._data.inviteText) {
          this.inviteText = this._data.inviteText;
        }
      });
    });

    if (!this._authService.isScopeAble('user_invitations:write')) {
      this.disabled = true;
      this.inviteText = 'Not authorized to invite users';
    } else {
      this._usersAppService.getAllUsers({ tilledAccount: this.accountId });
      this._usersAppService.getAllUserInvitations({ tilledAccount: this.accountId });
    }
    this.userInviteForm = this._formBuilder.group({
      email: new FormControl<string | null>(this._data.email || null, [Validators.required, Validators.email]),
    });
  }

  public inviteUser(action: string): void {
    this.action = action;

    if (!this.userInviteForm.invalid && !this.userInviteForm.disabled) {
      const params: CreateUserInvitationRequestParams = {
        tilledAccount: this.accountId,
        userInvitationCreateParams: {
          role:
            this.accountUserCount === 0
              ? UserInvitationCreateParams.RoleEnum.MERCHANT_OWNER
              : UserInvitationCreateParams.RoleEnum.MERCHANT_ADMIN,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          email_template:
            action === 'email'
              ? UserInvitationCreateParams.EmailTemplateEnum.MERCHANT_APPLICATION
              : UserInvitationCreateParams.EmailTemplateEnum.NONE,
          email: this.userInviteForm.getRawValue().email,
        },
      };
      this._usersAppService.inviteUser(params);

      this.invitationResponse$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
        next: (resp) => {
          if (resp.error?.message) {
            this.alertMessage =
              resp?.error?.message ?? 'System Error, please try again or contact Tilled for assistance.';
            this._displayAlert$.next(true);
            this._fuseAlertService.show('invitationDialogAlertBox');
            return;
          }
          let message: TilledAlert;
          if (action === 'copy') {
            this._clipboard.copy(resp.invitation_url);
            message = {
              message: `User invitation link for ${resp.email} copied`,
              title: 'User invitation link copied',
              type: 'success',
              timer: 8000,
            };
          } else if (action === 'email') {
            message = {
              message: `User invitation for ${resp.email} sent successfully`,
              title: 'User invitation sent',
              type: 'success',
              timer: 8000,
            };
          }
          this._alertService.showAlert(message);

          if (this.applicantInvite) {
            this.closeDialog('continue');
          } else {
            this.closeDialog();
          }
        },
      });
    }
  }

  public closeDialog(action?: string): void {
    if (action) {
      switch (action) {
        case 'continue':
          this.dialogRef.close('continue');
          break;
        case 'changeApplicant':
          this.dialogRef.close('changeApplicant');
          break;
      }
    } else {
      this.dialogRef.close();
    }
  }

  navigateToUsers(): void {
    this.closeDialog();
    this._router.navigate([`/merchants/${this.accountId}`], { queryParams: { tab: 'users' } });
  }
}

export interface InviteMerchantDialogData {
  accountId: string;
  title: string;
  legalName: string;
  inviteText?: string;
  email: string;
  showCopyUrlButton: boolean;
  submitButtonText: string;
  showLinkToUsers: boolean;
  emailExists: boolean;
  applicantInvite?: boolean;
}
