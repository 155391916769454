/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BalanceTransactionFeeTypeSummary { 
    amount: number;
    count: number;
    currency: BalanceTransactionFeeTypeSummary.CurrencyEnum;
    fee_subtype?: BalanceTransactionFeeTypeSummary.FeeSubtypeEnum;
    fee_type: BalanceTransactionFeeTypeSummary.FeeTypeEnum;
}
export namespace BalanceTransactionFeeTypeSummary {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type FeeSubtypeEnum = 'approved_verification' | 'declined_verification' | 'approved_authorization' | 'declined_authorization' | 'reversed_authorization' | 'completed_credit' | 'declined_credit' | 'completed_settlement' | 'declined_settlement' | 'approved_purchase_return_authorization' | 'declined_purchase_return_authorization' | 'acquirer_processing' | 'amex_card_not_present' | 'amex_dues_and_assessments' | 'amex_inbound' | 'amex_international_assessment' | 'discover_assessment' | 'discover_data_usage' | 'dues_and_assessments' | 'fixed_acquirer_network' | 'mastercard_acquirer_brand_volume' | 'mastercard_acquirer_merchant_advice_code' | 'mastercard_acquirer_program_support' | 'mastercard_annual_location' | 'mastercard_assessment' | 'mastercard_assessment_1000_plus' | 'mastercard_authorization' | 'mastercard_cross_border_scheme' | 'mastercard_digital_commerce_development' | 'mastercard_digital_enablement' | 'mastercard_digital_enablement_max' | 'mastercard_final_authorization' | 'mastercard_final_authorization_min' | 'mastercard_international_assessment' | 'mastercard_nominal_amount_authorization' | 'mastercard_processing_integrity' | 'mastercard_safety_net_acquirer' | 'misuse_of_authorization' | 'nabu' | 'transaction_integrity' | 'visa_acquirer_credit_voucher_international' | 'visa_assessment' | 'visa_base_ii_transmission' | 'visa_commercial_solution' | 'visa_debit_assessment' | 'visa_international_assessment' | 'visa_misuse_of_authorization' | 'visa_us_digital_commerce' | 'reversal' | 'discount' | 'transaction' | 'account_setup' | 'ach_return' | 'monthly_account' | 'monthly_minimum_processing' | 'card_account_update' | 'monthly_terminal' | 'retrieval' | 'apple_pay_balance_transfer' | 'goods_and_services' | 'harmonized_sales';
    export const FeeSubtypeEnum = {
        APPROVED_VERIFICATION: 'approved_verification' as FeeSubtypeEnum,
        DECLINED_VERIFICATION: 'declined_verification' as FeeSubtypeEnum,
        APPROVED_AUTHORIZATION: 'approved_authorization' as FeeSubtypeEnum,
        DECLINED_AUTHORIZATION: 'declined_authorization' as FeeSubtypeEnum,
        REVERSED_AUTHORIZATION: 'reversed_authorization' as FeeSubtypeEnum,
        COMPLETED_CREDIT: 'completed_credit' as FeeSubtypeEnum,
        DECLINED_CREDIT: 'declined_credit' as FeeSubtypeEnum,
        COMPLETED_SETTLEMENT: 'completed_settlement' as FeeSubtypeEnum,
        DECLINED_SETTLEMENT: 'declined_settlement' as FeeSubtypeEnum,
        APPROVED_PURCHASE_RETURN_AUTHORIZATION: 'approved_purchase_return_authorization' as FeeSubtypeEnum,
        DECLINED_PURCHASE_RETURN_AUTHORIZATION: 'declined_purchase_return_authorization' as FeeSubtypeEnum,
        ACQUIRER_PROCESSING: 'acquirer_processing' as FeeSubtypeEnum,
        AMEX_CARD_NOT_PRESENT: 'amex_card_not_present' as FeeSubtypeEnum,
        AMEX_DUES_AND_ASSESSMENTS: 'amex_dues_and_assessments' as FeeSubtypeEnum,
        AMEX_INBOUND: 'amex_inbound' as FeeSubtypeEnum,
        AMEX_INTERNATIONAL_ASSESSMENT: 'amex_international_assessment' as FeeSubtypeEnum,
        DISCOVER_ASSESSMENT: 'discover_assessment' as FeeSubtypeEnum,
        DISCOVER_DATA_USAGE: 'discover_data_usage' as FeeSubtypeEnum,
        DUES_AND_ASSESSMENTS: 'dues_and_assessments' as FeeSubtypeEnum,
        FIXED_ACQUIRER_NETWORK: 'fixed_acquirer_network' as FeeSubtypeEnum,
        MASTERCARD_ACQUIRER_BRAND_VOLUME: 'mastercard_acquirer_brand_volume' as FeeSubtypeEnum,
        MASTERCARD_ACQUIRER_MERCHANT_ADVICE_CODE: 'mastercard_acquirer_merchant_advice_code' as FeeSubtypeEnum,
        MASTERCARD_ACQUIRER_PROGRAM_SUPPORT: 'mastercard_acquirer_program_support' as FeeSubtypeEnum,
        MASTERCARD_ANNUAL_LOCATION: 'mastercard_annual_location' as FeeSubtypeEnum,
        MASTERCARD_ASSESSMENT: 'mastercard_assessment' as FeeSubtypeEnum,
        MASTERCARD_ASSESSMENT_1000_PLUS: 'mastercard_assessment_1000_plus' as FeeSubtypeEnum,
        MASTERCARD_AUTHORIZATION: 'mastercard_authorization' as FeeSubtypeEnum,
        MASTERCARD_CROSS_BORDER_SCHEME: 'mastercard_cross_border_scheme' as FeeSubtypeEnum,
        MASTERCARD_DIGITAL_COMMERCE_DEVELOPMENT: 'mastercard_digital_commerce_development' as FeeSubtypeEnum,
        MASTERCARD_DIGITAL_ENABLEMENT: 'mastercard_digital_enablement' as FeeSubtypeEnum,
        MASTERCARD_DIGITAL_ENABLEMENT_MAX: 'mastercard_digital_enablement_max' as FeeSubtypeEnum,
        MASTERCARD_FINAL_AUTHORIZATION: 'mastercard_final_authorization' as FeeSubtypeEnum,
        MASTERCARD_FINAL_AUTHORIZATION_MIN: 'mastercard_final_authorization_min' as FeeSubtypeEnum,
        MASTERCARD_INTERNATIONAL_ASSESSMENT: 'mastercard_international_assessment' as FeeSubtypeEnum,
        MASTERCARD_NOMINAL_AMOUNT_AUTHORIZATION: 'mastercard_nominal_amount_authorization' as FeeSubtypeEnum,
        MASTERCARD_PROCESSING_INTEGRITY: 'mastercard_processing_integrity' as FeeSubtypeEnum,
        MASTERCARD_SAFETY_NET_ACQUIRER: 'mastercard_safety_net_acquirer' as FeeSubtypeEnum,
        MISUSE_OF_AUTHORIZATION: 'misuse_of_authorization' as FeeSubtypeEnum,
        NABU: 'nabu' as FeeSubtypeEnum,
        TRANSACTION_INTEGRITY: 'transaction_integrity' as FeeSubtypeEnum,
        VISA_ACQUIRER_CREDIT_VOUCHER_INTERNATIONAL: 'visa_acquirer_credit_voucher_international' as FeeSubtypeEnum,
        VISA_ASSESSMENT: 'visa_assessment' as FeeSubtypeEnum,
        VISA_BASE_II_TRANSMISSION: 'visa_base_ii_transmission' as FeeSubtypeEnum,
        VISA_COMMERCIAL_SOLUTION: 'visa_commercial_solution' as FeeSubtypeEnum,
        VISA_DEBIT_ASSESSMENT: 'visa_debit_assessment' as FeeSubtypeEnum,
        VISA_INTERNATIONAL_ASSESSMENT: 'visa_international_assessment' as FeeSubtypeEnum,
        VISA_MISUSE_OF_AUTHORIZATION: 'visa_misuse_of_authorization' as FeeSubtypeEnum,
        VISA_US_DIGITAL_COMMERCE: 'visa_us_digital_commerce' as FeeSubtypeEnum,
        REVERSAL: 'reversal' as FeeSubtypeEnum,
        DISCOUNT: 'discount' as FeeSubtypeEnum,
        TRANSACTION: 'transaction' as FeeSubtypeEnum,
        ACCOUNT_SETUP: 'account_setup' as FeeSubtypeEnum,
        ACH_RETURN: 'ach_return' as FeeSubtypeEnum,
        MONTHLY_ACCOUNT: 'monthly_account' as FeeSubtypeEnum,
        MONTHLY_MINIMUM_PROCESSING: 'monthly_minimum_processing' as FeeSubtypeEnum,
        CARD_ACCOUNT_UPDATE: 'card_account_update' as FeeSubtypeEnum,
        MONTHLY_TERMINAL: 'monthly_terminal' as FeeSubtypeEnum,
        RETRIEVAL: 'retrieval' as FeeSubtypeEnum,
        APPLE_PAY_BALANCE_TRANSFER: 'apple_pay_balance_transfer' as FeeSubtypeEnum,
        GOODS_AND_SERVICES: 'goods_and_services' as FeeSubtypeEnum,
        HARMONIZED_SALES: 'harmonized_sales' as FeeSubtypeEnum
    };
    export type FeeTypeEnum = 'discount' | 'transaction' | 'interchange' | 'pass_through' | 'platform' | 'chargeback' | 'administrative' | 'tax' | 'negative_rounding_adjustment' | 'positive_rounding_adjustment' | 'unknown';
    export const FeeTypeEnum = {
        DISCOUNT: 'discount' as FeeTypeEnum,
        TRANSACTION: 'transaction' as FeeTypeEnum,
        INTERCHANGE: 'interchange' as FeeTypeEnum,
        PASS_THROUGH: 'pass_through' as FeeTypeEnum,
        PLATFORM: 'platform' as FeeTypeEnum,
        CHARGEBACK: 'chargeback' as FeeTypeEnum,
        ADMINISTRATIVE: 'administrative' as FeeTypeEnum,
        TAX: 'tax' as FeeTypeEnum,
        NEGATIVE_ROUNDING_ADJUSTMENT: 'negative_rounding_adjustment' as FeeTypeEnum,
        POSITIVE_ROUNDING_ADJUSTMENT: 'positive_rounding_adjustment' as FeeTypeEnum,
        UNKNOWN: 'unknown' as FeeTypeEnum
    };
}


